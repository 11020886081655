import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { UserModel } from "../../../shared/models/user.model";
import { AuthService } from "../_services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RedirectHelper } from "../../../shared/helpers/redirect.helper";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  defaultAuth: any = {
    email: "",
    password: "",
  };
  loginForm: UntypedFormGroup;
  hasError: boolean;
  saving = false;
  returnUrl: string;
  isLoading$: Observable<boolean>;
  @Input() fullPageLogin = true;
  @Output() closeModalEvent = new EventEmitter<void>();
  @Output() register: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() forgotPassword: EventEmitter<boolean> = new EventEmitter<boolean>();

  // private fields
  private unsubscribe: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isLoading$ = this.authService.isLoading$;
    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      this.router.navigate([RedirectHelper.loginRedirectUrl]);
    }
  }

  ngOnInit(): void {
    this.initForm();
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl".toString()] || "/";
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: [
        this.defaultAuth.email,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320),
        ]),
      ],
      password: [
        this.defaultAuth.password,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  submit() {
    if (this.loginForm.valid) {
      this.hasError = false;
      this.saving = true;
      const loginSubscr = this.authService
        .login(this.f.email.value, this.f.password.value, this.fullPageLogin)
        .pipe(first())
        .subscribe((user: UserModel) => {
          if (!user) {
            this.hasError = true;
          }
          this.saving = false;
        });
      this.unsubscribe.push(loginSubscr);
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  handleCloseModal(): void {
    this.closeModalEvent.emit();
  }

  emitRegisterEvent(): void {
    this.register.emit(true);
  }

  emitForgotPassWordEvent(): void {
    this.forgotPassword.emit(true);
  }

  get lastVisitedRestaurantUrl(): string {
    return RedirectHelper.lastVisitedRestaurantUrl;
  }
}
