import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Subscription, Observable} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {ConfirmPasswordValidator} from './confirm-password.validator';
import {UserModel} from '../../../shared/models/user.model';
import {first} from 'rxjs/operators';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  registrationForm: UntypedFormGroup;
  hasError: boolean;
  isLoading$: Observable<boolean>;
  saving = false;

  private modalRef: NgbModalRef;

  @Input() fullPageRegister = true;
  @Input() displayBackButton = true;
  @Output() register: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() login: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeModalEvent = new EventEmitter<void>();

  @ViewChild('termsAndConditionsModal') termsAndConditionsModal: TemplateRef<any>;
  @ViewChild('payyoTermsAndConditionsModal') payyoTermsAndConditionsModal: TemplateRef<any>;
  @ViewChild('privacyPolicyModal') privacyPolicyModal: TemplateRef<any>;

  private unsubscribe: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private elementRef: ElementRef,
    private translate: TranslateService
  ) {
    this.isLoading$ = this.authService.isLoading$;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit() {
    this.setModalContent('.termsAndConditionsModal', this.termsAndConditionsModal, 'Dinego GmbH');
    this.setModalContent('.payyoTermsAndConditionsModal', this.payyoTermsAndConditionsModal, 'Payyo by Trekksoft AG');
    this.setModalContent('.privacyPolicyModal', this.privacyPolicyModal, 'privacy policy');
  }

  private setModalContent(selector: string, modal: TemplateRef<any>, translationKey: string) {
    const element = this.elementRef.nativeElement.querySelector(selector);

    if (element) {
      element.addEventListener('click', () => this.openModal(modal));

      this.translate.get(translationKey).subscribe((translation: string) => {
        element.textContent = translation;
      });
    }
  }

  get f() {
    return this.registrationForm.controls;
  }

  initForm() {
    this.registrationForm = this.fb.group(
      {
        firstName: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(100),
          ]),
        ],
        lastName: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(100),
          ]),
        ],
        phone: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320),
          ]),
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        passwordRepeat: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        agree: [true, Validators.compose([Validators.required])],
        agreePP: [true, Validators.compose([Validators.required])],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  submit() {
    if (this.registrationForm.valid && this.registrationForm.controls['agree'].value && this.registrationForm.controls['agreePP'].value) {
      this.saving = true;
      this.hasError = false;
      const result = {};
      Object.keys(this.f).forEach(key => {
        result[key] = this.f[key].value;
      });
      const newUser = new UserModel(result);
      const registrationSubscr = this.authService
        .registration(newUser)
        .pipe(first())
        .subscribe((user: UserModel) => {
          if (!user) {
            this.hasError = true;
          }
          this.saving = false;
        });
      this.unsubscribe.push(registrationSubscr);
    } else {
      this.registrationForm.markAllAsTouched();
    }
  }

  emitLoginEvent(): void {
    this.login.emit(true);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  handleCloseModal(): void {
    this.closeModalEvent.emit();
  }

  openModal(modal) {
    this.modalRef = this.modalService.open(modal, {
      size: 'lg',
      scrollable: true
    });
  }
}
