import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {first} from 'rxjs/operators';

enum ErrorStates {
  NotSubmitted,
  HasError,
  NoError,
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;
  errorState: ErrorStates = ErrorStates.NotSubmitted;
  errorStates = ErrorStates;
  isLoading$: Observable<boolean>;
  submitting = false;

  @Input() fullPageForgotPassword = true;
  @Input() displayBackButton = true;
  @Output() forgotPassword: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeModalEvent = new EventEmitter<void>();

  // private fields
  private unsubscribe: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) {
    this.isLoading$ = this.authService.isLoading$;
  }

  ngOnInit(): void {
    this.initForm();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  initForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320),
        ]),
      ],
    });
  }

  submit() {
    if (this.forgotPasswordForm.valid) {
      this.submitting = true;
      this.errorState = ErrorStates.NotSubmitted;
      const forgotPasswordSubscr = this.authService
        .forgotPassword(this.f.email.value)
        .pipe(first())
        .subscribe((result: boolean) => {
          this.errorState = result ? ErrorStates.NoError : ErrorStates.HasError;
          this.submitting = false;
        });
      this.unsubscribe.push(forgotPasswordSubscr);
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }

  handleCloseModal(): void {
    this.closeModalEvent.emit();
  }


  emitForgotPasswordEvent(): void {
    this.forgotPassword.emit(true);
  }
}
